import { LanguageService } from '@core/services/language.service';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: LanguageService.getLanguage(),
    fallbackLng: 'th',
    preload: ['th'],
    ns: ['common', 'translation'],
    defaultNS: 'translation',
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    debug: process.env.NODE_ENV !== 'production',
    react: {
      useSuspense: false,
      wait: true,
    },
  });

export default i18n;
