export interface Pagable {
  totalElements: number;
}

export interface PagingQuery {
  sort: string | null;
  size: number | undefined;
  page: number | undefined;
}

export interface PagableResponse<T> extends Pagable {
  content: T[];
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
