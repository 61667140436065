import { Routers } from '@core/@routes/Routers';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import localeData from 'dayjs/plugin/localeData';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

dayjs.extend(buddhistEra);
dayjs.extend(localeData);

const App = () => {
  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  );
};

export default App;
