const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const ROLE_ID = 'roleId';
const MENU_ID = 'menuId';
const SUB_MENU_ID = 'subMenuId';
const ROUTE_PATH = 'routePath';
const SIDE_MENUS = 'sideMenus';
const CODE_VERIFIER = 'verifier';
export class AuthService {
  public static setToken(token: string): void {
    localStorage.setItem(TOKEN, token);
  }

  public static setRefreshToken(token: string): void {
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  public static getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public static setRoleId(roleId: string): void {
    if (!roleId) return;
    localStorage.setItem(ROLE_ID, roleId);
  }

  public static getRoleId(): string | null {
    return localStorage.getItem(ROLE_ID);
  }

  public static setMenuId(menuId: string): void {
    if (!menuId) return;
    localStorage.setItem(MENU_ID, menuId);
  }

  public static getMenuId(): string | null {
    return localStorage.getItem(MENU_ID);
  }

  public static setSubMenuId(subMenuId: string): void {
    if (!subMenuId) return;
    localStorage.setItem(SUB_MENU_ID, subMenuId);
  }

  public static getSubMenuId(): string | null {
    return localStorage.getItem(SUB_MENU_ID);
  }

  public static setRoutePath(path: string): void {
    if (!path) return;
    localStorage.setItem(ROUTE_PATH, path);
  }

  public static getRoutePath(): string {
    return localStorage.getItem(ROUTE_PATH) || '';
  }

  public static removeToken(): void {
    localStorage.removeItem(TOKEN);
  }

  public static setSideMenus(sideMenu: string): void {
    if (!sideMenu) return;
    localStorage.setItem(SIDE_MENUS, sideMenu);
  }

  public static getSideMenus(): string | null {
    return localStorage.getItem(SIDE_MENUS);
  }

  public static setCodeVerifier(verifiter: string): void {
    localStorage.setItem(CODE_VERIFIER, verifiter);
  }

  public static getCodeVerifier(): string {
    return localStorage.getItem(CODE_VERIFIER) || '';
  }
}
